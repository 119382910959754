import { variables, mq } from 'cssInJs';
import { settings as defaultSettings } from 'site/tileList/config';

export function getTileStyle(settings = defaultSettings) {
  const numOfTilesPerBreakpoints = settings.numOfTilesPerBreakpoints
  const marginLR = settings.marginLR
  const marginTB = settings.marginTB

  return {
    tile: mq({
      paddingLeft: marginLR.map(x => x === null ? null : x / 2),
      paddingRight: marginLR.map(x => x === null ? null : x / 2),
      paddingTop: marginTB.map(x => x === null ? null : x / 2),
      paddingBottom: marginTB.map(x => x === null ? null : x / 2),
      maxWidth: numOfTilesPerBreakpoints.map(x => x === null ? null : 100 / x + '%'),
      flexBasis: numOfTilesPerBreakpoints.map(x => x === null ? null : 100 / x + '%'),
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'none',
        '.tileBg': {
          transform: 'scale(1.02)',
          //transition: 'all .2s ease-in-out'
        },
        '.tileContentBg': {
          //backgroundImage: 'url(/assets/page-tile-background-hover.png)',
          backgroundColor: variables.colorRed,
          borderBottomColor: variables.colorBlue,
          //zIndex: -1
        },
        '.tileContent': {
          //backgroundColor: rgba(variables.primaryColor, 0.3),
          //transform: 'scale(1.02)',
          //transition: 'all .2s ease-in-out'
          '.tileContent__title': {
            color: 'white'
          },
          '.tileContent__des': {
            color: 'white'
          }
        }
      }
    }),
    tileSlider: mq({
      maxWidth: '100% !important'
    }),
    titleContainer: {
      boxShadow: '3px 5px 10px rgba(0,0,0,0.3)',
      //overflow: 'hidden'
    },
    tileBg: mq({
      backgroundColor: 'white',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: 0,
      paddingBottom: '56.25%',
      transition: 'all .2s ease-in-out'
    }),

    tileContentBg: mq({
      //backgroundImage: 'url(/assets/page-tile-background.png)',
      backgroundColor: variables.colorBlue,
      paddingTop: ['45%',null,null,null,'36%'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      transition: 'all .2s ease-in-out',
      borderBottom: '4px solid',
      borderBottomColor: variables.colorRed,
    }),

    tileContent: mq({
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      //flexGrow: 1,
      lineHeight: 1.3,
      //boxShadow: '4px 4px 14px 4px rgba(190,190,190,0.5)',
      //backgroundColor: '#fff',
      //marginTop: [10],
      padding: ['2rem 2rem'],
      top: 0,
      width: '100%',
      height: '100%'
      //transition: 'all .2s ease-in-out',
    }),

    title: mq({
      fontFamily: variables.familyHeader,
      fontSize: '1.7rem',
      textTransform: 'uppercase',
      lineHeight: 1.3,
      fontWeight: 900,
      textAlign: 'center',
      color: 'white'
    }),
    desc: mq({
      marginTop: 5,
      fontSize: '0.8rem',
      display: '-webkit-box',
      WebkitLineClamp: '9',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textAlign: 'center',
      color: 'white',
      flex: '1 0 auto'
    }),
  }
}

export function getTileListOverridingStyle() {
  return {
    tiles: mq({
      alignItems: 'flex-start',
    })
  }
}