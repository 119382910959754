// eslint-disable-next-line
import React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import cmsUtils from "../../cms/utils/cmsUtils";
import utils from "../../utils";
import { useState, useEffect } from "react";
import { createPortal } from "react-dom";

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function StripeBuyBtn(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "stripeBuyBtn",
    item.cssClass || item.anchorName || ""
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [stripeURL, setStripeURL] = useState("");
  const australia = cmsUtils.payload(item, "Australia");
  const new_zealand = cmsUtils.payload(item, "NewZealand");
  const usa = cmsUtils.payload(item, "USA");
  const uk = cmsUtils.payload(item, "UK");
  const south_america = cmsUtils.payload(item, "SouthAmerica");
  const europe = cmsUtils.payload(item, "Europe");
  const china = cmsUtils.payload(item, "China");
  const other = cmsUtils.payload(item, "Other");
  function changeFunc(event) {
    setStripeURL(event.target.value);
  }

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <div className='button' onClick={() => setModalOpen(true)}>
        ADD TO CART
      </div>

      {modalOpen && (
        <Portal>
          {modalOpen && (
            <div className='modal'>
              <div className='modal__content'>
                <div className='modal_logo'>
                  <img src='/assets/logos/logo2.png' alt='logo' />
                </div>
                <p>
                  Please select your delivery zone to calculate the shipping
                  price.
                </p>
                <div className='modal__btns'>
                  <select id='myDropdown' onChange={changeFunc}>
                    <option disabled selected value>
                      {" "}
                    </option>
                    <option value={australia}>Australia</option>
                    <option value={new_zealand}>New Zealand</option>
                    <option value={usa}>USA</option>
                    <option value={uk}>UK</option>
                    <option value={south_america}>South America</option>
                    <option value={europe}>Europe</option>
                    <option value={china}>China</option>
                    <option value={other}>Other</option>
                  </select>
                </div>
                <div className='button'>
                  <a
                    href={stripeURL}
                    className='buttona'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Go To Check Out
                  </a>
                </div>
                <div className='close_btn' onClick={() => setModalOpen(false)}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M6 18 18 6M6 6l12 12'
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </Portal>
      )}
    </div>
  );
}
